<template>
  <v-container fluid v-if="!tid || !pid"></v-container>
  <v-container fluid v-else>
    <v-row>
      <v-col cols="12">
        <h2>{{ Discipline.name }}: {{ Person | person }} ({{ Club.name }})</h2>
      </v-col>
      <v-col order-xl="1" xl="6" cols="12">
        <v-col cols="12"><punktwaehler v-model="value.e1" label="E1" /></v-col>
        <v-col cols="12"><punktwaehler v-model="value.e2" label="E2" /></v-col>
      </v-col>
      <v-col order-xl="3" xl="6" cols="12">
        <v-col cols="12"><punktwaehler v-model="value.dscore" label="D-Note" /></v-col>
        <v-col cols="12"><punktwaehler v-model="value.penalty" label="Penalty" /></v-col>
        <v-col v-if="istSprung(did, df)" cols="12"><i>Hinweis: bei einem ungültigen 0-Punkte Sprung sämtliche Werte auf 0,0 belassen!</i></v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          :value="value.dscore | float2_0"
          label="D-Note"
          readonly
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          :value="escore | float2_0"
          :label="`E-Note (Abzug: ${deduction})`"
          readonly
        >
          <template #prepend>+</template>
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :value="value.penalty | float2_0"
          label="Penalty"
          readonly
        >
          <template #prepend>-</template>
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :value="final | float2_0"
          label="Endnote"
          readonly
          style="font-size: 150%;"
        >
          <template #prepend>=</template>
        </v-text-field>
      </v-col>
      <v-col cols="12" style="text-align: right;">
        <v-btn v-if="value._id" @click="del" style="margin-right:16px;">Löschen</v-btn>
        <v-btn @click="save" style="margin-right:16px;">Speichern und schließen</v-btn>
        <v-btn @click="close">Schließen</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import { useCalc } from '../../../plugins/calc'
import { resultquery } from '../query'
import gql from 'graphql-tag'
import Vue from 'vue'

export default {
  name: 'ergebnis',

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc()
    }
  },

  components: {
    Punktwaehler: () => import('./punktewaehler')
  },

  props: {
    did: {
      type: String,
      default: null
    },
    df: {
      type: Array,
      required: true
    },
    tid: {
      type: String,
      required: true
    },
    pid: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    subpart: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    value: {},
    Person: {},
    Club: {},
    Discipline: {}
  }),

  computed: {
    inline () {
      return !!['xl'].find(b => b === this.$vuetify.breakpoint.name)
    },
    deduction () {
      if (this.istSprung(this.did, this.df) && this.value.dscore === 0) return 0

      return Math.round((this.value.e1 + this.value.e2) * 50) / 100
    },
    escore () {
      if (this.istSprung(this.did, this.df) && !this.value.dscore) return 0
      return Math.round((10 - this.deduction) * 100) / 100
    },
    final () {
      if (this.istSprung(this.did, this.df) && this.value.dscore === 0) return 0
      return Math.max(0, Math.round((this.value.dscore + this.escore - this.value.penalty) * 100) / 100)
    }
  },

  methods: {
    del () {
      this.$emit('del')
    },
    save () {
      this.$emit('save', {
        ...this.value,
        subpart: this.subpart,
        deduction: this.deduction,
        escore: this.escore,
        final: this.final
      })
    },
    close () {
      this.$emit('close')
    }
  },

  apollo: {
    StbLtf2024TcTeamAthleteResult: {
      query: gql`query($event: UUID!, $tid: String!, $pid: UUID!, $did: UUID!, $subpart: Int) {
        StbLtf2024TcTeamAthleteResult(event: $event, tid: $tid, pid: $pid, did: $did, subpart: $subpart) { ${resultquery} }
      }`,
      variables () {
        return {
          event: this.e._id,
          tid: this.tid,
          pid: this.pid,
          did: this.did,
          subpart: this.subpart
        }
      },
      skip () {
        return !this.tid || !this.pid
      },
      result ({ data }) {
        if (!data) return
        this.value = JSON.parse(JSON.stringify(data.StbLtf2024TcTeamAthleteResult || { e1: 0, e2: 0, dscore: 0, penalty: 0 }))
        return null
      }
    },
    Person: {
      query: gql`query($id: UUID!) {
        Person(id: $id) { _id familyName givenName }
      }`,
      variables () {
        return {
          id: this.pid
        }
      },
      skip () {
        return !this.pid
      }
    },
    StbLtf2024TcTeamClub: {
      query: gql`query($event: UUID!, $tid: String!) {
        StbLtf2024TcTeamClub(event: $event, tid: $tid) { _id name }
      }`,
      variables () {
        return {
          event: this.e._id,
          tid: this.tid
        }
      },
      skip () {
        return !this.tid
      },
      result ({ data }) {
        if (!data) return
        this.Club = data.StbLtf2024TcTeamClub
        return null
      }
    },
    Discipline: {
      query: gql`query($id: UUID!) {
        Discipline(id: $id) { _id name }
      }`,
      variables () {
        return {
          id: this.did
        }
      }
    },
    $subscribe: {
      ResultCreate: {
        query: gql`subscription($event: UUID!) {
          ResultCreate(event: $event) { ${resultquery} }
        }`,
        variables () {
          return {
            event: this.e._id
          }
        },
        result ({ data }) {
          if (data.ResultCreate._id === this.value._id) {
            Vue.set(this, 'value', data.ResultCreate)
          }
        }
      },
      ResultUpdate: {
        query: gql`subscription($event: UUID!) {
          ResultUpdate(event: $event) { ${resultquery} }
        }`,
        variables () {
          return {
            event: this.e._id
          }
        },
        result ({ data }) {
          if (data.ResultUpdate._id === this.value._id) {
            Vue.set(this, 'value', data.ResultUpdate)
          }
        }
      },
      ResultDelete: {
        query: gql`subscription($event: UUID) {
          ResultDelete(event: $event)
        }`,
        variables () {
          return {
            event: this.e._id
          }
        },
        result ({ data }) {
          if (data.ResultDelete === this.value._id) {
            Vue.set(this, 'value', { e1: 0, e2: 0, dscore: 0, penalty: 0 })
          }
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
